export let timeout: string | number | NodeJS.Timeout | undefined
export const debounce = (func: () => void, delay: number = 300) => {
  clearTimeout(timeout)
  timeout = setTimeout(func, delay)
}

export let debounceValueTimeout: string | number | NodeJS.Timeout | undefined
export const debounceValue = (value: string, delay = 300) => {
  clearTimeout(debounceValueTimeout)
  return new Promise(resolve => {
    debounceValueTimeout = setTimeout(() => {
      resolve(value)
    }, delay)
  })
}

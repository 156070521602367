import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useMsal } from "@azure/msal-react"
import GridSystem from "../../../../../../../tailwind-grid/grid"
import ColSpanSix from "../../../../../../../tailwind-grid/col-span-six"
import { DisplayProductCard } from "../../../../../../../display-product-card"
import {
  getPreferredSupplierRates,
  getPurchaseOrderDetailById,
  getSupplierAddressesbySearchTerm,
  getSupplierPricing,
} from "../../../../../../../../middleware/middleware-layer"
import { useProductData } from "../../../../../../../../graphql-static/use-commercetools-products"
import { useContractContext } from "../../../../store"
import { IContractDetailItem } from "../../../../../../../../types/interfaces/IContractDetail"
import { CreatePOForm } from "../../../../../../../create-po-form"
import { debounceValue } from "../../../../../../../../utils/debounce"
import { LoadingFeedback } from "../../../../../../../loading-feedback"
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser"
import { navigate } from "gatsby-link"
import { IPreferredSupplierEquipmentRate } from "../../../../../../../../types/interfaces/IPreferredSupplierEquipmentRate"

const CreatePO = ({ poId, ...props }: any) => {
  const {
    createPOFormValues,
    setCreatePOFormValues,
    createPOItems,
    setCreatePOItems,
    remainingAvailableForPOItems,
    setRemainingAvailableForPOItems,
    contractSD,
  } = useContractContext()

  const [loadSpinner, setLoadSpinner] = useState<boolean>(false)
  const [editDataLoading, setEditDataLoading] = useState(false)
  const [poNotFound, setPoNotFound] = useState<boolean>(false)
  const [poDetails, setPoDetails] = useState<null | Record<string, any>>(null)
  const [selectedAvailableItemOption, setSelectedAvailableItemOption] = useState<null | Record<string, any>>(null)
  const [preferredSupplierEquipmentRates, setPreferredSupplierEquipmentRates] = useState<IPreferredSupplierEquipmentRate[]>([])
  const [consolidatedSupplierEquipmentRate, setConsolidatedSupplierEquipmentRate] = useState<null | IPreferredSupplierEquipmentRate>(null)

  const { accounts, instance } = useMsal()
  const productData = useProductData()

  const getSupplierPricingByItems = async (
    items: Record<string, any>[],
    supplierAccountNumber: string,
  ) => {
    const supplierPricingInput = items?.map((item: Record<string, any>) => {
      return {
        accountNumber: supplierAccountNumber,
        equipmentCode: item.stockNumber.replace('XH', ''),
      }
    })

    const result = await getSupplierPricing(
      accounts,
      instance,
      supplierPricingInput
    )

    return result?.data?.internalGetSupplierEquipmentPricing || []
  }

  // New list of supplier rates based on performance scorecard and machine/postcode data
  const getPreferredSupplierEquipmentRates = async (
    items: Record<string, any>[],
  ) => {
    let rates: IPreferredSupplierEquipmentRate[] = []
    let consolidated: null | IPreferredSupplierEquipmentRate = null

    if (contractSD.sitePostCode) {
      // Transport charge is delivery plus collection
      const deliveryCharge = contractSD.additionalItems.find((additionalItem: IContractDetailItem) => {
        return additionalItem.equipmentCategory === '_TRSP' && additionalItem.equipmentDesc === 'Delivery'
      })?.chargeAmount || "0"

      const collectionCharge = contractSD.additionalItems.find((additionalItem: IContractDetailItem) => {
        return additionalItem.equipmentCategory === '_TRSP' && additionalItem.equipmentDesc === 'Collection'
      })?.chargeAmount || "0"

      const transportCharge = parseFloat(deliveryCharge) + parseFloat(collectionCharge)
      const postcode = contractSD.sitePostCode.split(' ')[0].toUpperCase()

      const supplierRateInput: any = {
        customerId: contractSD.customerId,
        postcode: postcode,
        items: items.map((item: any) => {
          return {
            chargeAmount: parseFloat(item.chargeAmount),
            chargePeriod: item.chargePeriod || item.supplierItemChargePeriod,
            equipmentCode: item.sku || item.stockNumber.replace('XH', ''),
            equipmentDescription: item.equipmentDesc,
          }
        }),
        transportCharge,
      }

      await getPreferredSupplierRates(
        accounts,
        instance,
        supplierRateInput,
      ).then((result) => {
        if (result.data.internalGetSupplierRates) {
          rates = result.data.internalGetSupplierRates.rates
          consolidated = result.data.internalGetSupplierRates.consolidated
        }
      }).catch((e) => {
        console.log(e)
      })
    }

    setPreferredSupplierEquipmentRates(rates)
    setConsolidatedSupplierEquipmentRate(consolidated)
  }

  const promiseOptions = async (inputValue: string) => {
    if (inputValue.length >= 3) {
      const debouncedInputValue = await debounceValue(inputValue, 500)
      let options = []
      try {
        const supplierBranchResults = await getSupplierAddressesbySearchTerm(
          accounts,
          instance,
          debouncedInputValue as string
        ).then((result: any) => {
          if (
            typeof result.data.internalGetSupplierAddressesBySearchTerm !==
            "undefined"
          ) {
            return result.data.internalGetSupplierAddressesBySearchTerm
          } else {
            return []
          }
        })

        // Get a distinct list of suppliers - this is because the data could contain multiple branches for each 
        // supplier but we only want to show the supplier in the drop down list.
        const distinctSuppliers = supplierBranchResults.filter((supplier: any, index: number) => {
          return index === supplierBranchResults.findIndex((o: any) => supplier.supplierId === o.supplierId)
        })

        options = distinctSuppliers.map((item: Record<string, any>) => {
          return {
            label: item.supplierName,
            value: {
              supplierId: item.supplierId,
              supplierName: item.supplierName,
              supplierAccountNumber: item.supplierAccountNumber,
              // Only attach the suppliers branches to the supplier, the original list includes all branches 
              // for all suppliers
              branches: supplierBranchResults.filter((branch: any) => {
                return branch.supplierId === item.supplierId
              })
            },
          }
        })
        return options
      } catch (err) {
        console.log(err)
        return []
      }
    }
  }

  const mergeItemsWithPricingData = async (
    supplierPricing: Record<string, any>[],
    items: Record<string, any>[],
    loadData?: boolean
  ) => {
    const itemsWithPricingData = items?.map((poItem: Record<string, any>) => {
      const clonedPricingList = JSON.parse(JSON.stringify(supplierPricing))
      const pricingObj = clonedPricingList.find(
        (rate: Record<string, any>) => rate.equipmentCode === poItem.stockNumber.replace('XH', '')
      )
      const { supplierItemChargePeriod, supplierItemPurchaseRate } =
        poItem || {}

      if (pricingObj) {
        pricingObj.rates = resolveChargePeriodOptions(pricingObj)
      }

      // We can arrive here either with a PO already booked (loadData = true) or when a user
      // selects a supplier either manually or from the partner selector (loadData = false).
      // If loadData then the selected rate will be correct because the PO has the charge period.
      // If user selected supplier then we need to get the rates for the supplier and set
      // the purchase rate and hire period to the rate matching the contract hire period.
      const selectedRate = pricingObj?.rates?.find(
        (rate: Record<string, any>) =>
          rate.value.hirePeriodValue === (supplierItemChargePeriod || poItem.chargePeriod)
      )

      poItem.purchaseRate = loadData
        ? supplierItemPurchaseRate || 0
        : selectedRate?.value?.rateValue || 0

      poItem.hirePeriod = loadData
        ? selectedRate || ""
        : selectedRate || {
            label: "",
            value: "",
          }

      poItem.fleetNumber = poItem.supplierItemPlantNumber

      // If this has come from a PO (loadData = false) we need to add the contract amount and charge period
      // to the item so that the variance can be displayed in the DisplayProductCard component.
      if (loadData) {
        const contractItem = contractSD.items.find((item: Record<string, any>) => 
          item.stockNumber === poItem.stockNumber && item.sequenceNo.toString() === poItem.sequenceNo.toString()
        )
        
        console.log(contractItem)

        if (contractItem) {
          poItem.chargeAmount = contractItem.chargeAmount
          poItem.chargePeriod = contractItem.chargePeriod
        }
      }

      return { ...poItem, ...pricingObj }
    })
    return itemsWithPricingData
  }

  useEffect(() => {
    if (!contractSD) {
      navigate(-1)
    }
  }, [contractSD])

  useEffect(() => {
    const loadData = async () => {
      if (poId) {
        setCreatePOItems([])
        setEditDataLoading(true)
        const fetchPurchaseOrderDetailById = async (
          accounts: AccountInfo[],
          instance: IPublicClientApplication,
          poId: number
        ) => {
          const result = await getPurchaseOrderDetailById(
            accounts,
            instance,
            poId
          )
          return result?.data?.internalGetPurchaseOrderDetailById
        }

        const poDetails = await fetchPurchaseOrderDetailById(
          accounts,
          instance,
          +poId
        )

        if (!poDetails) {
          setPoNotFound(true)
        } else {
          const {
            supplierId,
            supplierAccountNumber,
            supplierName,
            supplierAdrId,
            supplierAdrName,
            supplierTown,
            supplierContactId,
            supplierContactFirstName,
            supplierContactSurname,
            supplierContactEmail,
            supplierContactMobileNumber,
            deliveryCharge,
            collectionCharge,
            showCustomerName,
            printNotes,
            contacts,
            items = [],
          } = poDetails || {}

          setPoDetails(poDetails)

          const splitNotes = printNotes?.split("###") || []
          const deliveryNotes =
            splitNotes?.length > 1 ? splitNotes[1] : splitNotes[0]

          // 1. fill all fields on the right-hand side of the page based on the data above. - e.g. supplier branch

          const selectedContact =
            contacts?.filter(
              (contact: Record<string, any>) =>
                contact.supplierContactId === +supplierContactId
            )[0] || null
          setCreatePOFormValues((prevState: Record<string, any>) => {
            return {
              ...prevState,
              supplier: {
                label: supplierName,
                value: {
                  supplierId,
                }
              },
              supplierBranch: {
                label: `${supplierAdrName || supplierTown || ""}`,
                value: {
                  supplierId,
                  supplierName,
                  supplierAdrId,
                  supplierAdrName,
                  supplierTown,
                  contacts,
                },
              },
              supplierContact: {
                label: `${supplierContactFirstName || ""} ${
                  supplierContactSurname || ""
                } - ${
                  supplierContactEmail || supplierContactMobileNumber || ""
                }`,
                value: selectedContact,
              },
              deliveryCharge,
              collectionCharge,
              showCustomerName,
              deliveryNotes,
            }
          })

          // 2. left-hand side of the page: items
          // 2-1. call supplier pricing endpoint and get pricing data for all items.
          const supplierPricing = await getSupplierPricingByItems(
            [...items, ...remainingAvailableForPOItems], // pass all items to get batch pricing data.
            supplierAccountNumber
          )

          // 2-2. update createPOItems and remainingAvailableForPOItems with pricing data
          const itemsWithPricingData = await mergeItemsWithPricingData(
            supplierPricing,
            items,
            !!poId
          )
          setCreatePOItems(itemsWithPricingData)

          const remainingItemsWithPricingData = await mergeItemsWithPricingData(
            supplierPricing,
            remainingAvailableForPOItems,
            false
          )
          setRemainingAvailableForPOItems(remainingItemsWithPricingData)
        }

        setEditDataLoading(false)
      }
    }
    loadData()
  }, [])

  const currentSupplierId = createPOFormValues?.supplier?.value?.supplierId
  const currentSupplier = createPOFormValues?.supplier?.value

  useEffect(() => {
    const loadData = async () => {
      if (createPOFormValues.supplier.value?.isPreferredSupplier !== true) {
        // Old way of collecting pricing when the supplier is searched for and a branch selected
        if (createPOItems?.length > 0 && currentSupplier) {
          setLoadSpinner(true)

          // 1. When currentSupplier changes, update items with new pricing.
          // 1-1. call supplier pricing endpoint and get pricing data for all items.
          const supplierPricing = await getSupplierPricingByItems(
            [...createPOItems, ...remainingAvailableForPOItems], // pass all items to get batch pricing data.
            currentSupplier.supplierAccountNumber
          )

          // 1-2. update createPOItems and remainingAvailableForPOItems with pricing data
          const itemsWithPricingData = await mergeItemsWithPricingData(
            supplierPricing,
            createPOItems,
            false // don't use pricing info from the poDetails, just use default hire period from the new pricing data.
          )
          setCreatePOItems(itemsWithPricingData)

          const remainingItemsWithPricingData = await mergeItemsWithPricingData(
            supplierPricing,
            remainingAvailableForPOItems,
            false // don't use pricing info from the poDetails, just use default hire period from the new pricing data.
          )
          setRemainingAvailableForPOItems(remainingItemsWithPricingData)

          // Set transport rates
          setCreatePOFormValues((prevState: Record<string, any>) => {
            const transport = isNaN(parseFloat(supplierPricing[0].transportStandard)) ? null : parseFloat(supplierPricing[0].transportStandard).toFixed(2)
            return {
              ...prevState,
              deliveryCharge: transport,
              collectionCharge: transport,
              supplier: {
                ...prevState.supplier,
                value: {
                  ...prevState.supplier.value,
                  reducedTransportPossible: supplierPricing[0].reducedTransportPossible,
                }
              },              
            }
          })

          setLoadSpinner(false)
        }
      }
    }
    loadData()
  }, [currentSupplierId])

  useEffect(() => {
    const loadData = async () => {
      if (currentSupplier.isPreferredSupplier === true) {
        // Supplier has been selected from the preferred list - populate the items with the correct rates
        const itemsWithPricingData = createPOItems?.map((poItem: Record<string, any>) => {
          const equipmentRate = preferredSupplierEquipmentRates.find((rate: Record<string, any>) => 
            rate.equipmentCode.toUpperCase() === poItem.stockNumber.replace('XH', '').toUpperCase()
          )
    
          if (equipmentRate) {
            // Get the rates for the supplier and push them into an options array for the dropdown
            const supplierRate = equipmentRate.suppliers.find((supplier: Record<string, any>) => 
              supplier.accountNumber === currentSupplier.supplierAccountNumber
            )

            const rates = resolveChargePeriodOptions(supplierRate)
    
            // Find the rate matching the item charge period and set the default values
            const selectedRate = rates.find((rate: Record<string, any>) =>
                rate.value.hirePeriodValue === poItem.chargePeriod
            )
    
            poItem.purchaseRate = selectedRate?.value?.rateValue || ""
      
            poItem.hirePeriod = selectedRate || {
              label: "",
              value: "",
            }
      
            return { ...poItem, ...{ rates: rates} }
          }
        })
        
        setCreatePOItems(itemsWithPricingData)
      }
    }
    if (currentSupplierId) {
      loadData()
    }
  }, [currentSupplierId])

  // If items are added or removed from the PO, update the preferred supplier rates to match
  useEffect(() => {
    const refreshPreferredSupplierEquipmentRates = async () => {
      await getPreferredSupplierEquipmentRates(createPOItems)
    }
    refreshPreferredSupplierEquipmentRates()
  }, [createPOItems.length])

  const handleCreatePOFormChange = async (
    key: string,
    value: string | Record<string, any> | boolean
  ) => {
    switch (key) {
      case "supplier": {
        setCreatePOFormValues((prevState: Record<string, any>) => {  
          // If there is only one branch, we want to automatically select the supplier branch drop down list
          const supplier = value as Record<string, any>
          let branch = { label: "", value: null }
          const transport = isNaN(supplier.value.transport) ? null : parseFloat(supplier.value.transport).toFixed(2)
          if (supplier?.value?.branches?.length === 1) {
            branch.label = supplier.value.branches[0].supplierAdrName
            branch.value = supplier.value.branches[0]
          }
          return  {
            ...prevState,
            [key]: value,
            supplierBranch: branch,
            supplierContact: { label: "", value: null },
            deliveryCharge: transport,
            collectionCharge: transport,
          }
        })
        break
      }

      case "supplierBranch": {
        setCreatePOFormValues((prevState: Record<string, any>) => {
          return  {
            ...prevState,
            [key]: value,
            supplierContact: { label: "", value: null },
          }
        })
        break
      }

      default: {
        setCreatePOFormValues({
          ...createPOFormValues,
          [key]: value,
        })
      }
    }
  }

  const handleCreatePOValuesArrChange = (
    id: string,
    key: string,
    value: any
  ) => {
    setCreatePOItems((prevArr: Record<string, any>) => {
      return prevArr.map((item: IContractDetailItem) => {
        if (item.sequenceNo === id) {
          switch (key) {
            case "purchaseRate":
              item.purchaseRate = value
              break
            case "hirePeriod":
              item.hirePeriod = value
              item.purchaseRate = value.value.rateValue || ""
              break
            case "fleetNumber":
              item.fleetNumber = value
          }

          return item
        }
        return item
      })
    })
  }

  const handleAddPOItem = () => {
    const selectedAvailableItem = selectedAvailableItemOption?.value
    if(selectedAvailableItem) {
      setCreatePOItems((currentItems: Record<string, any>[]) => {
        return [...currentItems, selectedAvailableItem ]
      })
      setRemainingAvailableForPOItems((currentItems: Record<string, any>[]) => {
        return currentItems.filter((item: Record<string, any>) => item?.sequenceNo !== selectedAvailableItem?.sequenceNo)
      })
      setSelectedAvailableItemOption(null)
    }
  }

  const { poRefId, userName } = poDetails || {}

  const resolveChargePeriodOptions = (supplierRates: any) => {
    let rates = []
        rates.push({ 
          label: "5-Week (Weekly)", 
          value: {
            hirePeriodValue: "5WEEK",
            rateValue: supplierRates.rateWeek?.toFixed(2),
          }
        })

        rates.push({ 
          label: "1-day", 
          value: {
            hirePeriodValue: "1 DAY",
            rateValue: supplierRates.rate1Day?.toFixed(2),
          }
        })

        rates.push({ 
          label: "2-day", 
          value: {
            hirePeriodValue: "2 DAY",
            rateValue: supplierRates.rate2To3Day?.toFixed(2),
          }
        })

        rates.push({ 
          label: "3-day", 
          value: {
            hirePeriodValue: "3 DAY",
            rateValue: supplierRates.rate2To3Day?.toFixed(2),
          }
        })

        rates.push({ 
          label: "4-day", 
          value: {
            hirePeriodValue: "4 DAY",
            rateValue: null,
          }
        })

        rates.push({ 
          label: "1-Month", 
          value: {
            hirePeriodValue: "MONTH",
            rateValue: supplierRates.rate4PlusWeeks?.toFixed(2)
          }
        })

    return rates
  }

  return (
    <>
      <LoadingFeedback
        showSpinner={loadSpinner || editDataLoading}
        noBackground={!editDataLoading}
        loadingText={`Fetching ${editDataLoading ? "PO" : "pricing"} data ...`}
      />
      <h1 className="mb-8 text-3xl font-bold uppercase">
        {poId ? "EDIT" : "CREATE"} PURCHASE ORDER{" "}
        {poRefId ? `- ${poRefId}` : ""}
      </h1>
      {poNotFound ? (
        <p>Purchase order not found</p>
      ) : (
        <GridSystem>
          <ColSpanSix>
            <div>
              <div className="pb-4">
                {createPOItems.map((item: IContractDetailItem) => {
                  return (
                    <DisplayProductCard
                      key={item.sequenceNo}
                      productName={item.equipmentDesc}
                      productCode={item.stockNumber}
                      productData={productData}
                      sequenceNo={item.sequenceNo}
                      hireRates={item.rates}
                      pathway="createPO"
                      supplierId={item.supplierId}
                      fleetNumber={item.fleetNumber}
                      isCreatePO={true}
                      handleChange={handleCreatePOValuesArrChange}
                      createPOPurchaseRate={item.purchaseRate}
                      createPOHirePeriod={item.hirePeriod}
                      chargeAmount={item.chargeAmount}
                      chargePeriod={item.chargePeriod}
                    />
                  )
                })}
              </div>
              {
                remainingAvailableForPOItems?.length > 0 && (
                  <div>
                    <p className="p-1 font-semibold">Select and add available remaining items for this purchase order</p>
                    <Select
                      isSearchable={false}
                      value={selectedAvailableItemOption}
                      onChange={option => {
                        setSelectedAvailableItemOption(option as Record<string, any>)
                      }}
                      options={
                        remainingAvailableForPOItems?.map((item: Record<string, any>) => {
                          return {
                            label: `${item?.stockNumber || ''} - ${item?.equipmentDesc}`,
                            value: item
                          }
                        })
                      }
                    />
                    {
                      selectedAvailableItemOption && (
                        <button className="p-2 text-right underline" onClick={handleAddPOItem}>
                          Add
                        </button>
                      )
                    }
                  </div>
                )
              }
            </div>
          </ColSpanSix>
          <ColSpanSix>
            <CreatePOForm
              poId={poId}
              customerId={props.customerId}
              contractId={props.contractId}
              contract={contractSD}
              createPOFormValues={createPOFormValues}
              handleCreatePOFormChange={handleCreatePOFormChange}
              items={createPOItems}
              promiseOptions={promiseOptions}
              raisedBy={poId ? userName : accounts[0]?.name}
              isSent={poDetails?.isSent}
              preferredSupplierEquipmentRates={preferredSupplierEquipmentRates}
              consolidatedSupplierEquipmentRate={consolidatedSupplierEquipmentRate}
            />
          </ColSpanSix>
        </GridSystem>
      )}
    </>
  )
}

export default CreatePO

import React, { ReactChildren } from "react"
import GridSystem from "../../../../tailwind-grid/grid"
import ColSpanTwelve from "../../../../tailwind-grid/col-span-twelve"
import { Contract } from "../../../contract"

export interface ContractDetailProps {
  contractId: string
  children: ReactChildren
}

export const ContractDetail = ({ contractId, children }: any) => {
  const showDetailPage = window.location.pathname.split("/").length === 4

  return (
    <GridSystem>
      <ColSpanTwelve>
        {showDetailPage ? (
          <Contract contractId={contractId} />
        ) : (
          <>{children}</>
        )}
      </ColSpanTwelve>
    </GridSystem>
  )
}
